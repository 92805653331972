  .marquee {
    --gap: 5px;
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
  }
  
  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
  }
  
  .marquee__content img {
    max-width: 2rem;
    width: 100%;
    object-fit: contain;
  }
  
  .marquee__content > * {
    flex: 0 0 auto;
    color: black;
    margin: 2px;
    text-align: center;
    font-size: 40px;
    font-weight: 800;
  }
  
  .marquee__item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  ul { padding-left: 0; }

  .enable-animation .marquee__content {
    animation: scroll 60s linear infinite;
  }

  @keyframes scroll {
    from { transform: translateX(0); }
    to { transform: translateX(calc(-100% - var(--gap))); }
  }