footer{
    display: flex;
    gap: 20px;
    padding: 40px;
    background-color: #161616;
    flex-wrap: wrap;
}

#legal{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

#dev-credit{
    width: 100%;
    text-align: center;
    color: white;
}
#dev-credit a{
    text-decoration: none;
    color: white;
}

@media (min-width: 1024px) {
    footer{
        flex-wrap: nowrap;
    }

    #legal{
        width: 60%;
        text-align: left;
    }
    
    #dev-credit{
        width: 40%;
    }
}