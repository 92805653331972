@import url('./Global.css');

#skills{
    background-color: black;
    background-image: url('./assets/skills.png');
    height: 100vh;
    max-width: 100vw;
    background-size:cover;
    background-repeat: no-repeat;
    height: fit-content;
}
#skills-wrapper{
    background-color: rgba(0, 0, 0, 0.571);
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

#skills-intro{
    display: flex;
    flex-direction: column;
}

.intro--left--word span{
    font-size: 40px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    transition: all 2s;
}
.intro--left--word:hover span:nth-child(2){
    padding-left: 60px;
}

#intro--right{
    font-size: 25px;
    color: white;
}

#skills-list{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
#skills-list div h3{
    font-size: 30px;
    color: white;
    font-weight: lighter;
    margin: 0;
}

.skills-list-container{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    overflow: auto;
}
.skill-item{
    padding: 10px;
    color: black;
    background-color: white;
    min-width: 200px;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border-radius: 20px;
    transition: all 1s;
    cursor: pointer;
}
.skill-item:hover{
    margin-left: 50px;
}

/* Desktop */
@media (min-width: 1024px) {

    #skills-wrapper{
        padding: 60px;
    }

    #skills-intro{
        flex-direction: row;
    }
    #intro--left,#intro--right{
        width: 50%;
    }

    .intro--left--word span{
        font-size: 70px;
    }
    .intro--left--word:hover span:nth-child(2){
        padding-left: 60px;
    }
}