.social-links {
    display: flex;
    flex-direction: column;
    font-size: 30px;
}

.social-links--align-left{
    text-align: left;
}
.social-links--align-right{
    text-align: right;
}

.social-links > a{
    text-decoration: none;
    color: white;
    font-weight: bold;
    transition: all 0.5s;
}

.social-links--align-right > a:hover{
    margin-right: 20px;
}
.social-links--align-left > a:hover{
    margin-left: 20px;
}