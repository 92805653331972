@import url('./Global.css');

#experience{
    padding: 20px;
    background-color: #181818;
}

.go-back{
    font-size: 25px;
    width: fit-content;
    cursor: pointer;
}

#experience--img{
    background-image: url('./assets/experience.png');
    border-radius: 20px;
    margin-bottom: 30px;
}

#experience--img--overlay{
    background-color: #0000007f;
    border-radius: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 40px;
    box-sizing: border-box;
    justify-content: center;
}
#experience--img--overlay span{
    transition: all 1s;
    cursor: pointer;
}
#experience--img--overlay span:hover{
    transform: translateX(20px);
}
#experience--img--overlay span:nth-child(1){
    font-size: 30px;
    font-weight: bold;
    color: transparent;
    -webkit-text-stroke: 1px white;
    text-shadow: white;
}
#experience--img--overlay span:nth-child(2){
    margin-left: 20px;
    font-size: 30px;
    font-weight: bold;
    color: white;
}
#experience--img--overlay span:nth-child(3){
    margin-left: 40px;
    font-size: 30px;
    font-weight: lighter;
    color: white;
}

#experience--text{
    width: 100%;
    font-size: 25px;
    font-weight: 400;
    color: white;
}

#experience--timeline{
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    overflow: auto;
}

#timeline--years{
    margin-bottom: 10px;
}
#timeline--years,#timeline--companies{
    white-space: nowrap;
}
#timeline--years div,.experience-card{
    text-align: center;
    font-size: 20px;
    color: white;
    width: 100%;
}
#timeline--years div{
    text-wrap: wrap;
    display: inline-block;
    max-width: 100%;
}

.experience-card {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 0 10px 0;
    transition: all 1s;
    cursor: pointer;
    border-radius: 20px;
    border: 2px dashed transparent;
    max-width: 100%;
}
.experience-card .experience-card--position{
    font-size: 30px;
}
.experience-card--position{
    text-wrap: wrap;
}
.experience-card .experience-card--company{
    font-size: 70px;
    font-weight: bolder;
}
.experience-card .experience-card--view{
    font-size: 30px;
    font-weight: lighter;
    margin-top: 20px;
}
.experience-card:hover{
    border-color: white;
}
#experience-detailed{
    padding: 20px;
}
.experience-detailed--content{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
.experience--about{
    font-size: 25px;
}
.experience--about div:nth-child(1){
    font-weight: bold;
}
.experience--details--img{
    width: 100%;
}
.experience--details--img img{
    max-width: 100%;
    width: 479px;
    max-height: 383px;
    height: auto;
    object-fit: cover;
    border-radius: 20px;
}
.experience--details, .experience--about{
    width: 100%;
    padding: 10px;
}

.experience--details{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.experience--detail{
    display: flex;
    flex-direction: column;
    font-size: 25px;
}
.experience--detail span:nth-child(1){
    font-weight: bold;
}

/* Desktop */
@media (min-width: 1024px) {

    #experience{
        padding: 60px;
    }
    
    #experience--intro{
        display: flex;
        gap: 30px;
    }

    #experience--img{
        width: 40%;
        max-width: 637px;
        height: 392px;
        margin-bottom: 0;
    }

    #experience--text{
        width: 60%;
    }

    #experience--img--overlay{
        gap: 30px;
    }
    #experience--img--overlay span:nth-child(1){
        font-size: 50px;
        -webkit-text-stroke: 3px white;
    }
    #experience--img--overlay span:nth-child(2){
        font-size: 50px;
        margin-left: 60px;
    }
    #experience--img--overlay span:nth-child(3){
        font-size: 50px;
        margin-left: 120px;
    }
    #experience--timeline{
        overflow: hidden;
    }
    
    #timeline--years,#timeline--companies{
        display: flex;
        justify-content: center;
        align-items: center;
        gap:10px;
    }
    #timeline--years div,.experience-card{
        width: calc(100%/3);
        font-size: 30px;
    }
    .experience-card {
        margin-top: 20px;
        padding: 10px 0 10px 0;
        transition: all 1s;
        cursor: pointer;
        border-radius: 20px;
        border: 2px dashed transparent;
    }
    #experience-detailed{
        padding: 60px;
    }
    .experience--details{
        flex-direction: column;
        border-right: 2px solid black;
    }
    .experience-detailed--content{
        flex-direction: row;
    }
    .experience--details, .experience--about{
        width: 50%;
        padding: 20px;
    }
}

@media (min-width: 1440px) {
    #experience--img--overlay{
        gap: 10px;
    }
    #experience--img--overlay span:nth-child(1){
        font-size: 70px;
    }
    #experience--img--overlay span:nth-child(2){
        font-size: 70px;
    }
    #experience--img--overlay span:nth-child(3){
        font-size: 70px;
    }
}