@import url('https://fonts.googleapis.com/css2?family=Jacques+Francois+Shadow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jacques+Francois&display=swap');

#contact{
    padding: 20px;
}
#contact--top{
    display: flex;
    gap: 20px;
    flex-direction: column;
}
#contact--top-img{
    max-width: 100%;
    height: 200px;
    width: 100%;
    background-image: url('./assets/Contact.png');
    background-repeat: no-repeat;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 20px;
}
#contact--img--overlay{
    width: 100%;
    height: 100%;
    background-color: #0000007f;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding: 40px;
    box-sizing: border-box;
    justify-content: center;
}
#contact--img--overlay span{
    transition: all 1s;
    cursor: pointer;
    font-size: 40px;
    color: white;
}
#contact--img--overlay span:hover{
    transform: translateX(20px);
}
#contact--img--overlay span:nth-child(1){
    font-family: "Jacques Francois Shadow", serif;
}
#contact--img--overlay span:nth-child(2){
    font-family: "Jacques Francois", serif;
    margin-left: 60px;
}
#contact--img--overlay span:nth-child(3){
    margin-left: 120px;
    font-weight: lighter;
}

#contact--top-info{
    padding-left: 0;
}
#cta-1 h2{
    margin: 0;
    font-weight: bolder;
    font-size: 40px;
}
#cta-2{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 60px;
}

#contact--top-info .social-links a{
    color: black;
}

#general-contacts{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#general-contacts a{
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: black;
}

@media (min-width: 1024px) {
    #contact{
        padding: 60px;
    }
    #contact--top{
        flex-direction: row;
    }

    #contact--top-img{
        max-width: 722px;
        height: 510px;
        width: 50%;
    }
    #contact--img--overlay span{
        font-size: 100px;
    }
    #contact--img--overlay span:nth-child(2){
        margin-left: 60px;
    }
    #contact--img--overlay span:nth-child(3){
        margin-left: 120px;
    }
    #contact--top-info{
        padding-left: 60px;
    }
    #cta-1 h2{
        font-size: 80px;
    }
    #cta-2{
        font-size: 30px;
        margin-bottom: 60px;
    }
    #general-contacts a{
        font-size: 25px;
    }
}