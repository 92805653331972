#projects{
    background-color: #E9B738;
    padding: 10px;
}
#projects-container{
    background-color: #161616;
    padding: 10px 15px 10px 15px;
    border-radius: 20px;
    box-shadow: 0 0 30px 10px;
}
#projects-container--top{
    display: flex;
    flex-direction: column-reverse;
    height: fit-content;
}
#number-of-items{
    width: 100%;
    text-align: center;
    color: white;
    font-size: 25px;
    font-weight: bold;
}
#my-projects-title{
    position: relative;
    /* top: -50px; */
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}
#projects-container--navidation{
    width: 100%;
    text-align: center;
    color: white;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
    height: 50px;
}

#projects-container--middle{
    display: flex;
    gap: 20px;
    padding: 30px 0 30px 0;
    font-size: 25px;
    overflow: auto;
    flex-direction: column;
}
.project-card{
    width: 100%;
    min-height: 300px;
    max-height: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.project-title{
    height: 50px;
}
.project-title h4{
    margin:  0;
    font-size: 30px;
}
.project-description{
    height: 250px;
}
.project-buttons{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}
.project-repo,.project-link{
    width: 100%;
    text-decoration: none;
    font-size: 25px;
    color: white;
    padding: 5px 20px 5px 20px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 20px;
}
.project-repo{
    background-color: #161616;
}
.project-link{
    background-color: #E9B738;
}

@media (min-width: 1024px) {
    
    #projects{
        padding: 100px;
    }
    #projects-container{
        padding: 30px 60px 30px 60px;
    }
    #projects-container--middle {
        flex-direction: row;
        overflow: hidden;
    }

    #projects-container--top{
        flex-direction: column;
        height: 50px;
    }
    #number-of-items{
        /* width: 100%;
        text-align: center;
        color: white;
        font-size: 25px;
        font-weight: bold; */
    }
    #my-projects-title{
        top: -50px;
        font-size: 60px;
        text-align: left;
    }

    .project-card {
        width: 50%;
        min-height: 500px;
        max-height: 100%;
    }

    .project-title{
        height: 20%;
    }
    .project-title h4{
        margin:  0;
        font-size: 35px;
    }
    .project-description{
        height: 60%;
    }
    .project-buttons{
        height: 20%;
        flex-direction: row;
        gap:20px;
    }
    .project-repo,.project-link{
        width: 50%;
    }

    #projects-container--navidation{
        height: auto;
    }
}