#image-slider{
    width: 100%;
}
#image-slider--main{
    display: flex;
    justify-content: center;
    padding: 5px;
}
.img-slider--img{
    width: 80%;
    min-width: 80%;
    display: flex;
    justify-content: center;
}
.img-slider--img img{
    max-width: 100%;
    height: 400px;
    border-radius: 20px;
    object-fit: cover;
}

#image-slider--main--btn-left,#image-slider--main--btn-right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    cursor: pointer;
}

#image-slider--dot-counter{
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: center;
    padding: 5px;
}
.image-slider--dot{
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #D9D9D9;
}

.image-slider--dot--active{
    background-color: #161616;
}

@media (min-width: 1024px) {
    .img-slider--img{
        min-width: 300px;
    }
    .img-slider--img img{
        max-width: 500px;
    }
}