#education{
    background-color: #19417E;
    padding: 20px;
}
#education--top{
    display: flex;
    flex-direction: column-reverse;
}
/* #education--top > div:nth-child(2){
    display: none;
} */
#education--list{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.education--list-item{
    display: flex;
    /* flex-wrap: wrap; */
    gap: 20px;
    cursor: pointer;
}
.list-item--number span {
    font-size: 40px;
    font-weight: bold;
    color: #5E89CC;
    transition: all 1s;
}
.list-details{
    display: flex;
    flex-direction: column;
}
.list-details--school{
    font-size: 20px;
    font-weight: bold;
    color: #5E89CC;
}
.list-details--course{
    font-size: 30px;
    color: white;
}
.list-details--year{
    font-weight: bolder;
    font-size: 30px;
    color: white;
}

.education--list-item:hover .list-item--number span{
    color: white;
}

#education--details{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px
}
#education--institution-img{
    width: 100%;
    height: 200px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
}
#education--institution-img span{
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
}
#education--info{
    font-size: 25px;
    color: white;
}

/* Desktop */
@media (min-width: 1024px) {
    #education{
        padding: 60px;
    }

    #education--details{
        margin-top: 100px;
        flex-wrap: nowrap;
    }

    #education--institution-img{
        width: 20%;
    }
    #education--info{
        width: 80%;
    }

    #education--top{
        flex-direction: row;
    }
    /* #education--top div :nth-child(2){
        display: inline-block;
    } */

    #education--list{
        width: 50%;
    }

    .list-item--number span {
        font-size: 60px;
    }

    .list-details--school{
        font-size: 30px;
    }
    .list-details--course{
        font-size: 40px;
    }
    .list-details--year{
        font-size: 30px;
    }

}