/* Mobile */
.nav{
    max-width: 100%;
    max-height: 30px;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding:30px;
    background-color: #161616;
    position: sticky;
    top: 0;
    backdrop-filter: blur(2px);
    z-index: 6000;
    transition: all 1s;
}
.nav-item{
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-in-out 0.4s;
    box-shadow: inset 0 0 0 0 #FFFFFF;
    padding: 15px;
    border-radius: 10px;
    color: white;
    text-decoration: none;
}
.nav-item:hover{
    color: black;
}

#nav--mobile-button{
    display: inline;
    height: 100%;
    background-color: #161616;
}

@media (max-width: 1024px) {
    .nav {
        position: sticky;
        top: -100%;
    }
    .nav-closed #nav-outer{
        display: none;
    }
    .nav-open{
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        max-height: 100vh;
        height: 100%;
        width: 100vw;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .nav-open #nav--mobile-button{
        height: fit-content;
        width: 100%;
    }
    .nav-open #nav-outer{
        margin-top: 10px;
        display: flex;
        gap: 20px;
        height: 100%;
        width: 100%;
        flex-direction: column;
        background-color: black;
        padding: 10px 30px 10px 30px;
        box-sizing: border-box;
        overflow: auto;
        border-radius: 20px;
    }
}

/* Desktop */
@media (min-width: 1024px) {
    .nav{
        height: 30px;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0);
    }
    #nav--mobile-button{
        display: none;
    }
    #nav-outer{
        width: fit-content;
        height: 100%;
        background-color: #161616;
        color:white;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        padding: 20px;
        justify-content: center;
        align-items: center;
        gap: 25px;
        border-radius: 20px;
        cursor: pointer;
    }
}

/* Animation classes */
.slide-down:hover {
    box-shadow: inset 0 100px 0 0 #FFFFFF;
}

.slide-right:hover {
    box-shadow: inset 400px 0 0 0 #FFFFFF;
}

.slide-left:hover {
    box-shadow: inset -400px 0 0 0 #FFFFFF;
}

.slide-diagonal:hover {
    box-shadow: inset 400px 50px 0 0 #FFFFFF;
}

.slide-up:hover{
    box-shadow: inset 0 -100px 0 0 #FFFFFF;
}

.slide-other-2:hover{
    box-shadow: inset 0 0 50px 50px #FFFFFF;
}