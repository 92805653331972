.box{
    width: 100%;
    padding-bottom: 50px;
}

.box--text-list {
    position: relative;
    z-index: 2;
    top: -100%;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.box-text{
    box-sizing: border-box;
    width: fit-content;
    color: transparent;
    -webkit-text-stroke: 1px #5E89CC;
    text-shadow: #5E89CC;
    font-size: 40px;
    padding-left: 5%;
    transition: all 1s;
    cursor: pointer;
}
.box-text:hover{
    -webkit-text-stroke: 3px white;
    text-shadow: white;
}

/* Desktop */
@media (min-width: 1024px) {
    .box{
        width: 50%;
    }

    .box-text{
        -webkit-text-stroke: 3px #5E89CC;
        padding-left: 25%;
        font-size: 80px;
    }

    .box--squares{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    .box--square{
        display: inline-block;
        width: 100%;
        height: 75%;
        /* background-color: #183B71; */
        background-color: transparent;
        /* border: 2px solid #183B71; */
        border: 3px solid #112a51;
        position: relative;
        transition: all 2s;
    }
    /* .box--square:nth-child(1){
        background-color: #112a51;
    }
    .box--square:nth-child(2){
        background-color: #183B71;
    } */

    /* POSITIONING */
    .box--square:nth-child(1){
        transform: translate(25%,25%);
    }

    /* ANIMATIONS */
    .position-1 .box--square:nth-child(1){
        transform: translate(25%,25%);
    }
    .position-1 .box--square:nth-child(2){
        transform: translate(0%,0%);
    }

    .position-2 .box--square:nth-child(1){
        transform: translate(100%,50%);
    }
    .position-2 .box--square:nth-child(2){
        transform: translate(-50%,0%);
    }

    .position-3 .box--square:nth-child(1){
        transform: translate(75%,50%);
    }
    .position-3 .box--square:nth-child(2){
        transform: translate(-100%,25%);
    }

    .position-4 .box--square:nth-child(1){
        transform: translate(0%,0%);
    }
    .position-4 .box--square:nth-child(2){
        transform: translate(-25%,25%);
    }

    .position-5 .box--square:nth-child(1){
        transform: translate(75%,0%);
    }
    .position-5 .box--square:nth-child(2){
        transform: translate(-100%,50%);
    }

    .position-6 .box--square:nth-child(1){
        transform: translate(0%,25%);
    }
    .position-6 .box--square:nth-child(2){
        transform: translate(-25%,50%);
    }
}