@import url('./Global.css');

#about{
    padding: 10px;
}

#about-content--left--thats-me{
    font-size: 30px;
    /* width: 80%; */
    position: relative;
    padding: 0 0 30px 0;
    color: #161616;
}
#about-content--left--favourites{
    font-size: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: #161616;
}

/* Desktop */
@media (min-width: 1024px) {
    #about{
        padding: 60px;
    }
    #about-content{
        display: flex;
    }

    #about-content--left,#about-content--right{
        width: 50%;
    }
    #about-content--right p{
        font-size: 25px;
    }

    #about-content--left--thats-me{
        font-size: 50px;
        padding: 0 0 30px 20%;
    }
    #about-content--left--favourites{
        padding-left: 20%;
    }
}