@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

.scrolldown-btn{
    display: inline-block;
    width: 150px;
    height: 25px;
    padding: 10px;
    position: absolute;
    margin: 0 auto;
    bottom: 10%;
    background-color: white;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    animation: bounce 3s infinite;
}
.scrolldown-btn a{
    text-decoration: none;
    font-family: "JetBrains Mono", monospace;
    font-optical-sizing: auto;
    font-weight: bolder;
    font-style: normal;
    font-size: 20px;
    color: black;
}
/* Small Laptop */
@media (min-width: 1024px) {

}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}