#hero {
    background-color: #161616;
    min-height: 100vh;
    max-width: 100vw;
    background-size:cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

#profile-img-mobile{
    display: inline-block;
    width: 100%;
    height: 150px;
    background-image: url('./assets/JG_1.jpg');
    background-size:cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-bottom: 30px;
    position: relative;
    /* left: 20%;
    right: 20%; */
}

#hero-left ,#hero-right{
    height: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

#hero-left--head{
    margin-bottom: 10px;
}

#hero--qualification{
    font-size: 15px;
    font-weight: bold;
    color: white;
}
#hero--name{
    font-size: 25px;
    font-weight: bolder;
    color: white;
}
#hero--title{
    font-size: 20px;
    font-weight: bold;
    color: white;
}

#hero-left--quote{
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin: 30px 0 30px 0;
}

#hero-left--contact a{
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 16px;
}

@media (max-width: 1024px) {
    #hero-right--head .social-links{
        text-align: left !important;
        font-size: 20px;
    }
    #hero-left--quote{
        display: none;
    }
}

/* Small Laptop */
@media (min-width: 1024px) {
    #hero {
        flex-direction: row;
        height: 100vh;
        padding: 0;
        background-image: url('./assets/headphones.jpg');
        justify-content: center;
    }
    #profile-img-mobile{
        display: none;
    }
    #hero-left {
        width: 50%;
        padding: 60px;
        padding-bottom: 10%;
        background-color: rgba(0, 0, 0, 0.753);
        backdrop-filter: blur(3px);
    }
    #hero-left--info {
        height: 100%;
        padding: 30px;
        border-left: 2px solid white;
        color: white;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    
    #hero-right{
        width: 50%;
        height: 100%;
        padding: 60px;
        box-sizing: border-box;
    }
    
    #hero-left--head{
        height: 33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    #hero--qualification{
        font-size: 1.4rem;
        font-weight: bold;
    }
    #hero--name{
        font-size: 3rem;
        font-weight: bolder;
    }
    #hero--title{
        font-size: 2rem;
        font-weight: bold;
    }
    
    #hero-left--quote{
        margin-bottom: 10px;
        font-size: 1.5rem;
        font-weight: bold;
        height: 35%;
        display: flex;
        align-items: center;
    }
    
    #hero-left--contact{
        display: flex;
        flex-direction: column;
        justify-content: end;
        height: 35%;    
    }
    #hero-left--contact a{
        width: 100%;
        text-decoration: none;
        color: white;
        font-weight: bold;
        font-size: 1rem;
    }
}


/* Desktop */
@media (min-width: 1440px) {



    #hero--qualification{
        font-size: 2rem;
        font-weight: bold;
    }
    #hero--name{
        font-size: 4rem;
        font-weight: bolder;
    }
    #hero--title{
        font-size: 2.5rem;
        font-weight: bold;
    }
    
    #hero-left--quote{
        font-size: 2rem;
        font-weight: bold;
        height: 35%;
        display: flex;
        align-items: center;
    }
    
    #hero-left--contact{
        display: flex;
        flex-direction: column;
        justify-content: end;
        height: 35%;    
    }
    #hero-left--contact a{
        width: 100%;
        text-decoration: none;
        color: white;
        font-weight: bold;
        font-size: 1.5rem;
    }
}